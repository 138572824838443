<template>
  <div id="app">
    <div class="bg-white flex flex-col h-screen justify-between">
        <Header class="h-0"></Header>
        <main class="mb-auto">
          <RouterView></RouterView>
        </main>
        <Footer class="h-auto"></Footer>
    </div>
  </div>
</template>

<script>
import Header from './partials/Header';
import Footer from './partials/Footer';
import RouterView from 'vue-router'

export default {
  name: 'app',
  components: {Header, RouterView, Footer},
}
</script>

