import {createApp} from 'vue';
import { createWebHistory, createRouter } from "vue-router";
import App from './App.vue'
import Homepage from "./pages/Homepage";
import store from './store'

import './assets/styles/tailwind.css';

// Vue.config.productionTip = false
// Vue.use(VueRouter);
// Vue.use(Vuelidate);

const routes = [
    {name: 'Home', path: '/', component: Homepage},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App);

app.config.devtools = true;
app.use(store);
app.use(router);

app.mount('#app');
