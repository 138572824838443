
// initial state
const state = {
    // loggedIn: false,
}

// actions
const actions = {
    // setUserId ({ commit }, id) {
    //     commit('setUser', {id: id, name: ''})
    // },
    // setHeaderTitle ({ commit }, headerTitle) {
    //     commit('setHeaderTitle', headerTitle)
    // },
}

// mutations
const mutations = {
    // setUser (state, payload) {
    //     state.user = payload
    //     state.loggedIn = true
    //     api.setUserId(payload.id)
    // },
    // setHeaderTitle (state, payload) {
    //     state.currentHeaderTitle = payload
    // }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
